$primary: #365a82;
$primary-strong: #1c344f;
$abb: #ec1b24;

$body-color: $primary-strong;

$input-btn-padding-y: 0.25rem !default;
$input-btn-padding-x: 0.5rem !default;

$line-height-sm: 1.25 !default;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1.15 !default;
$h6-font-size: $font-size-base !default;

$custom-file-text: (
  en: "Parcourir"
) !default;