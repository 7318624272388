/* You can add global styles to this file, and also import other style files */
@import "./_variables.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "../node_modules/ngx-toastr/toastr-bs4-alert";

@import "../node_modules/jsoneditor/dist/jsoneditor.min.css";

html,
body {
  height: 100%;
  background: $light;
}

table,
table thead,
table thead,
th {
  border-top: none !important;
}

dl {
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.25rem;
}

.form-control.ng-invalid {
  border: 1px solid $danger;
}

.paletteWallsDiv {
  min-height: 150px;
}

/* SCROLLBAR */
*::-webkit-scrollbar {
  width: 8px;
}
*::-webkit-scrollbar-track {
  background: #aeaeae;
}
*::-webkit-scrollbar-thumb {
  background-color: #777;
  border-radius: 20px;
  border: 2px solid #aeaeae;
}

/* NAV MENU BUTTON TOGGLE */
.btn-toggle > button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.btn-toggle > .btn-group button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-toggle .dropdown-toggle::after {
  margin-left: 0;
}

.btn-toggle .btn-group button {
  padding: 0.375rem 0.5rem;
}

.btn-group .dropdown-menu li a {
  font-size: 0.9rem;
}

.btn-toggle ul#dropdown-basic {
  padding: 0.25rem 0;
}

.btn-toggle li.divider.dropdown-divider {
  margin: 0.25rem 0;
}

/* SIDENAV NAV */
.sidebar {
  display: flex;
  background-color: $primary;
}

.sidebar .tools {
  max-width: 70px;
  display: flex;
  flex-direction: column;
}
.sidebar .tools > a {
  width: 70px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar .tools > div {
  width: 70px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar .tools a svg {
  fill: #eee;
}
.sidebar .tools a.disabled svg {
  fill: #333;
}
.sidebar .tools a.active {
  background-color: $light;
}
.sidebar .tools a.active svg {
  fill: $primary;
}

.mode-active a {
  background: #4873a2;
  border-radius: 0.5rem;
  border: 1px solid #30547d;
}

.sidebar tabset.tab-container a.nav-link.active {
  border-radius: 0;
}
.sidebar tabset.tab-container .tab-content {
  width: 100%;
}

.sidebar {
  background-color: $primary;
}

.sidebar,
.sidebar tabset {
  height: 100%;
}

.sidebar .tab-content,
.sidebar .nav-pills .nav-link:hover {
  background-color: darken($color: $primary, $amount: 10);
  border-radius: 0;
}

.sidebar .tab-content,
.sidebar .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: $light;
  transition: background-color 100ms;
}

.nav-item svg-icon svg {
  fill: white;
}
.nav-item.active svg-icon svg {
  fill: #4d4f4f;
}

.nav-item.disabled svg-icon svg {
  fill: #77b;
}

/* SIDE MODAL */

.full-modal.modal-dialog {
  position: absolute;
  height: 100vh;
  width: 100%;
  max-width: 100% !important;
  left: 0;
  top: 0;
  margin: 0 !important;
}

.right-modal.modal-dialog {
  right: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
  box-shadow: 0 1rem 3rem rgba(52, 58, 64, 0.175) !important;
}

.modal-content {
  // height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
}

.modal-body {
  background: #ffffff;
}

.modal-footer {
  background: #ffffff;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -webkit-transform: translate(100, 0);
  transform: translate(100%, 0);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0%, 0);
  transform: translate(0%, 0);
}

.right-modal .modal-body {
  overflow-y: auto;
  flex: 0 1 100%;
}

/* PRINT */
.print-preview svg {
  width: 100% !important;
  height: 100% !important;
  max-width: 100%;
  max-height: 100%;
  border: 3px dashed #aeaeae;
}

@media print {
  @page {
    size: var(--paper);
  }

  * {
    box-shadow: none !important;
    overflow: hidden;
  }
  .diag-properties,
  #sidebar {
    border: none !important;
  }
  .noprint {
    display: none !important;
  }

  .print-preview {
    height: auto !important;
  }
  .print-preview svg {
    max-width: 100% !important;
    max-height: 100% !important;
  }

  .full-modal.modal-dialog {
    height: auto;
  }

  .modal-content {
    overflow: hidden;
    box-shadow: none !important;
    max-height: 100% !important;
    height: auto;
  }

  .modal-open .modal {
    overflow: hidden;
  }
  #svgHolder {
    padding: 0 !important;
  }
}

/* SPINNER */

@media (min-width: 576px) {
  .spinnerFile.modal-dialog {
    max-width: inherit;
    margin: inherit;
  }
}
.spinnerFile.modal-dialog {
  position: inherit;
  margin: 0;
}
.spinnerFile.modal-dialog .modal-content {
  background-color: transparent;
}
.spinnerFile {
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    stroke: #93bfec;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.gsfBackground {
  border-color: #fc6f86;
  opacity: 65%;
  margin-bottom: 9px;
}
